import { FC, SyntheticEvent } from "react";
import { SxProps, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MessageSquare } from "react-feather";



export type CommentButtonProps = {
  share: {
    commentCount: number
  },
  showText?: boolean,
  onClick: (ev: SyntheticEvent) => void,
  sx?: SxProps,
}


export const CommentButton: FC<CommentButtonProps> = function CommentButton({ share, showText=false, onClick, sx={} }) {

  const theme = useTheme();

  return (
    <Typography
      variant="body2"
      color="text.secondary"
      noWrap
      sx={{ display: 'flex', alignItems: 'center', ...sx }}
      onClick={onClick}
    >
      <MessageSquare
        style={{
          stroke: theme.palette.grey[500],
          marginRight: '4px',
        }}
        width={14}
        height={14}
      />
      {showText &&
        <>
          {share.commentCount === 0 &&
            <>Comment</>
          }
          {share.commentCount === 1 &&
            <>{share.commentCount} Comment</>
          }
          {share.commentCount > 1 &&
            <>{share.commentCount} Comments</>
          }
        </>
      }
      {!showText && share.commentCount >= 1 &&
        <>{share.commentCount}</>
      }
    </Typography>
  );
}
