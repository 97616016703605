import PropTypes from 'prop-types';
import { AvatarGroup as MuiAvatarGroup } from '@mui/material';


export function AvatarGroup({ children, size, sx, ...rest }) {

  let width = '32px', height = '32px', fontSize = '18px', borderWidth = '2px';
  if (size === 'small') {
    width = '20px';
    height = '20px';
    fontSize = '12px';
    borderWidth = '1.5px';
  } else if (size === 'large') {
    width = '40px';
    height = '40px';
    fontSize = '20px';
    borderWidth = '2px';
  } else if (size === 'xlarge') {
    width = '64px';
    height = '64px';
    fontSize = '24px';
    borderWidth = '2px';
  } else if (size === 'xsmall') {
    width = '16px';
    height = '16px';
    fontSize = '8px';
    borderWidth = '1.5px';
  }

  return (
    <MuiAvatarGroup
      sx={{
        '&& span.MuiAvatar-colorDefault, && div.MuiAvatar-colorDefault':{
          fontSize: fontSize,
          borderWidth,
          borderStyle: 'solid',
          borderColor: sx?.borderColor || '#fff',
          backgroundColor: 'grey.300',
          color: 'text.primary',
          fontWeight: 500,
          width: width,
          height: height,
        },
        '&& span.MuiAvatar-root, && div.MuiAvatar-root':{
          borderColor: sx?.borderColor || '#fff',
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </MuiAvatarGroup>
  );
}

AvatarGroup.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string,
  sx: PropTypes.object,
}
