import { useEffect, useRef, useState, useCallback, FC, ReactNode } from 'react';
import { motion, useAnimation, useMotionValue } from 'framer-motion';
import { Box, SxProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

import { CheckCircle, CheckCircleFilled, AddCircle, AddCircleFilled } from '../icons';
import { useSession } from '../../hooks/auth';
import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { useLoginPush } from '../../hooks/login-push';


const START = 0;
const LEFT_HOLD = 70;
const LEFT_SET = 100;
const LEFT_CLOSE = -220;
const RIGHT_HOLD = -70;
const RIGHT_SET = -100;
const RIGHT_CLOSE = 220;

interface SwipeableUnfurlProps {
  children: ReactNode;
  isWatched: number;
  setIsWatched: (isWatched: boolean) => void;
  isInWatchlist: boolean;
  setIsInWatchlist: (isInWatchlist: boolean) => void;
  backgroundSx?: SxProps;
  showLabel?: boolean;
}

export const SwipeableUnfurl: FC<SwipeableUnfurlProps> = function SwipeableUnfurl({ children, isWatched, setIsWatched, isInWatchlist, setIsInWatchlist, backgroundSx = {}, showLabel = true }) {

  const { user } = useSession();
  const { track } = useAnalyticsQueued();
  const { setLoginHint } = useLoginPush();

  const [ state, setState ] = useState('idle');
  const [ drag, setDrag ] = useState(false);
  const timeoutHandleRef = useRef<ReturnType<typeof setTimeout>|null>(null);

  const [ expectedIsWatched, setExpectedIsWatched ] = useState(!!isWatched);
  const [ expectedIsInWatchlist, setExpectedIsInWatchlist ] = useState(isInWatchlist);

  const x = useMotionValue(0);
  const controls = useAnimation();

  const theme = useTheme();
  const backgroundColor = theme.palette.primary.main;
  const contrastColor = theme.palette.primary.darkContrastText;

  const handleToggleIsWatched = useCallback(() => {
    if(user) {
      setIsWatched(!isWatched);
    } else {
      track('swipe_toggle_watched', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in to save this.');
    }
  }, [ isWatched, setIsWatched, user, track, setLoginHint ]);

  const handleToggleIsInWatchlist = useCallback(() => {
    if(user) {
      setIsInWatchlist(!isInWatchlist);
    } else {
      track('swipe_toggle_watchlist', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in to save this.');
    }
  }, [ isInWatchlist, setIsInWatchlist, user, track, setLoginHint ]);

  // set timeout and clear previous timeout
  const timer = useCallback((cb: () => void, t: number) => {
    if(timeoutHandleRef.current) {
      clearTimeout(timeoutHandleRef.current);
    }
    timeoutHandleRef.current = setTimeout(cb, t);
  }, []);

  const stopPropagation = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const onDragStart = useCallback(async () => {
    setState('active');
    setDrag(true);
    await Haptics.selectionStart();
  }, []);

  const onDragEnd = useCallback(async () => {
    setDrag(false);
    const dragPosition = x.get();
    if (dragPosition > LEFT_SET) {
      controls.start('left');
      timer(() => controls.start('start'), 800);
    } else if (dragPosition < RIGHT_SET) {
      controls.start('right');
      timer(() => controls.start('start'), 800);
    } else {
      controls.start('start');
    }
    if(expectedIsWatched !== !!isWatched) {
      handleToggleIsWatched();
    }
    if(expectedIsInWatchlist !== isInWatchlist) {
      handleToggleIsInWatchlist();
    }
    return () => timeoutHandleRef.current && clearTimeout(timeoutHandleRef.current);
  }, [ x, controls, expectedIsWatched, isWatched, handleToggleIsWatched, expectedIsInWatchlist, isInWatchlist, handleToggleIsInWatchlist, timer ]);

  // handle drag states
  useEffect(() => {

    async function onChange(position: number) {
      // Don't allow inf dragging
      if (position > RIGHT_CLOSE || position < LEFT_CLOSE) {
        setState('resetting');
        controls.start('start');

      // dragging right
      } else if (drag && position < 0) {

        // if has dragged far enough to set
        if(position < RIGHT_SET) {
          // if to only toggle when crossed threshold and not on every drag
          if(expectedIsWatched === !!isWatched) {
            Haptics.impact({ style: isWatched ? ImpactStyle.Light : ImpactStyle.Medium });
            setExpectedIsWatched(!isWatched);
          }
        } else {
          // if dragged back to cancel toggle
          if(expectedIsWatched !== !!isWatched) {
            setExpectedIsWatched(!!isWatched);
          }
        }

      // dragging left
      } else if (drag && position > 0) {

        // if has dragged far enough to set
        if(position > LEFT_SET) {
          // if to only toggle when crossed threshold and not on every drag
          if(expectedIsInWatchlist === isInWatchlist) {
            Haptics.impact({ style: isInWatchlist ? ImpactStyle.Light : ImpactStyle.Medium });
            setExpectedIsInWatchlist(!isInWatchlist);
          }
        } else {
          // if dragged back to cancel toggle
          if(expectedIsInWatchlist !== isInWatchlist) {
            setExpectedIsInWatchlist(isInWatchlist);
          }
        }

      // bounced back to middle, nothing is happening
      } else if (position === START) {
        setState('idle');
        await Haptics.selectionEnd();
      }
    }

    return x.onChange(onChange);

  }, [ x, drag, controls, isWatched, isInWatchlist, expectedIsWatched, expectedIsInWatchlist, setState, setExpectedIsWatched, setExpectedIsInWatchlist ]);

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: state !== 'idle' ? backgroundColor : 'transparent',
        borderRadius: '9px', // 9 instead of 8 to prevent some bg color bleed
        zIndex: 0,
        ...backgroundSx
      }}
    >

      {/* Left action */}
      <Box
        sx={{
          position: 'absolute',
          height: '100%',
          width: '70px',
          top: '50%',
          left: 0,
          display: 'flex',
          gap: 0.5,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transform: 'translateY(-50%)',
          zIndex: 1,
          borderRadius: '8px',
          color: contrastColor
        }}
        onClick={stopPropagation}
      >
        {expectedIsInWatchlist ?
          (<AddCircleFilled sx={{ width: 20, height: 20 }} />) :
          (<AddCircle sx={{ width: 20, height: 20 }} />)
        }
        {showLabel &&
          <Typography component='span' variant="subtitle1" textAlign="center" lineHeight="1">
            {expectedIsInWatchlist ? 'Added' : 'Add'}
          </Typography>
        }
      </Box>

      {/* The unfurl is draggable */}
      <motion.div
        dragDirectionLock
        drag={state !== 'resetting' ? "x" : false}
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={0.5}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        className="msg-container"
        animate={controls}
        transition={{
          type: "spring",
          damping: 40,
          stiffness: 400
        }}
        variants={{
          start: { x: START },
          left: { x: LEFT_HOLD },
          right: { x: RIGHT_HOLD },
        }}
        style={{
          x,
          position: 'relative',
          zIndex: 2,
          borderRadius: '8px',
          boxShadow: state !== 'idle' ? '0 0 2px 6px rgba(0, 0, 0, 0.1)' : 'none',
        }}
      >
        {children}
      </motion.div>

      {/* Right action */}
      <Box
        sx={{
          position: 'absolute',
          height: '100%',
          width: '70px',
          top: '50%',
          right: 0,
          display: 'flex',
          gap: 0.5,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transform: 'translateY(-50%)',
          zIndex: 1,
          borderRadius: '8px',
          color: contrastColor
        }}
        onClick={stopPropagation}
      >
        {expectedIsWatched ?
          (<CheckCircleFilled sx={{ width: 20, height: 20 }} />) :
          (<CheckCircle sx={{ width: 20, height: 20 }} />)
        }
        {showLabel &&
          <Typography component='span' variant="subtitle1" textAlign="center" lineHeight="1">
            Seen
          </Typography>
        }
      </Box>

    </Box>
  );
}
