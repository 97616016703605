import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { FormControl, FormLabel, List, ListItem, ListItemSecondaryAction, ListItemText, Switch } from '@mui/material';

import { UNSUBSCRIBE_FROM_EMAIL } from '../../queries/user';
import { useAnalyticsQueued } from '../../hooks/delicious-analytics';


export function Notifications({ user, legend }) {

  const { track } = useAnalyticsQueued();

  const [ unsubscribeFromEmail ] = useMutation(UNSUBSCRIBE_FROM_EMAIL);

  const isEmailEnabled = !user.unsubscribed.some(u => u.category === 'notifications' && !u.deleted);
  const isDirectEnabled = !user.unsubscribed.some(u => u.category === 'direct' && !u.deleted);
  const isMentionsEnabled = !user.unsubscribed.some(u => u.category === 'mentions' && !u.deleted);
  const isDailyEnabled = !user.unsubscribed.some(u => u.category === 'daily' && !u.deleted);
  const isWeeklySummaryEnabled = !user.unsubscribed.some(u => u.category === 'weekly-summary' && !u.deleted);
  const isNowStreamingEnabled = !user.unsubscribed.some(u => u.category === 'now-streaming' && !u.deleted);
  const isNewSeasonEnabled = !user.unsubscribed.some(u => u.category === 'new-season' && !u.deleted);
  const isNewsletterEnabled = !user.unsubscribed.some(u => u.category === 'newsletter' && !u.deleted);

  const toggleEmailUnsubscribed = (category, enable) => {
    unsubscribeFromEmail({ variables: { category, deleted: enable } });
    track('toggle_email', { category: 'user-settings', 'unsubscribe_category': category, label: `${enable}` });
  }

  return (
    <FormControl component="fieldset" fullWidth>
      <FormLabel component="legend">{legend}</FormLabel>
      <List dense={true}>

        <ListItem>
          <ListItemText
            primary={<FormLabel component="legend">Weekly summary</FormLabel>}
            secondary={'A weekly summary of your friends activity'}
          />
          <ListItemSecondaryAction>
            <Switch checked={isWeeklySummaryEnabled} onChange={() => toggleEmailUnsubscribed('weekly-summary', !isWeeklySummaryEnabled)} />
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText
            primary={<FormLabel component="legend">Direct messages</FormLabel>}
            secondary={'For your eyes only. From someone you know.'}
          />
          <ListItemSecondaryAction>
            <Switch checked={isDirectEnabled} onChange={() => toggleEmailUnsubscribed('direct', !isDirectEnabled)} />
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText
            primary={<FormLabel component="legend">Mentions</FormLabel>}
            secondary={'Get notified when someone @-mentions you. Must be important.'}
          />
          <ListItemSecondaryAction>
            <Switch checked={isMentionsEnabled} onChange={() => toggleEmailUnsubscribed('mentions', !isMentionsEnabled)} />
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText
            primary={<FormLabel component="legend">Activity you might have missed</FormLabel>}
            secondary={"A daily mail with new things relevant to you."}
          />
          <ListItemSecondaryAction>
            <Switch checked={isDailyEnabled} onChange={() => toggleEmailUnsubscribed('daily', !isDailyEnabled)}
          />
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText
            primary={<FormLabel component="legend">Now streaming</FormLabel>}
            secondary={'Something in watchlist is made available for streaming.'}
          />
          <ListItemSecondaryAction>
            <Switch checked={isNowStreamingEnabled} onChange={() => toggleEmailUnsubscribed('now-streaming', !isNowStreamingEnabled)} />
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText
            primary={<FormLabel component="legend">New season</FormLabel>}
            secondary={'A new season of a show you follow is available.'}
          />
          <ListItemSecondaryAction>
            <Switch checked={isNewSeasonEnabled} onChange={() => toggleEmailUnsubscribed('new-season', !isNewSeasonEnabled)} />
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText
            primary={<FormLabel component="legend">Updates from your friends</FormLabel>}
            secondary={'Recommendations and shares from your friends.'}
          />
          <ListItemSecondaryAction>
            <Switch checked={isEmailEnabled} onChange={() => toggleEmailUnsubscribed('notifications', !isEmailEnabled)} />
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem>
          <ListItemText
            primary={<FormLabel component="legend">Newsletter</FormLabel>}
            secondary={'News about the service and new features.'}
          />
          <ListItemSecondaryAction>
            <Switch checked={isNewsletterEnabled} onChange={() => toggleEmailUnsubscribed('newsletter', !isNewsletterEnabled)} />
          </ListItemSecondaryAction>
        </ListItem>

      </List>
    </FormControl>
  );
}

Notifications.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    emails: PropTypes.array,
    contacts: PropTypes.array,
    groups: PropTypes.array,
    channels: PropTypes.array,
    unsubscribed: PropTypes.array,
  }),
  legend: PropTypes.string,
}
