import { forwardRef, ReactNode, SyntheticEvent, useCallback } from 'react';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { useHistory } from 'react-router';

import { useAnalyticsQueued } from '../hooks/delicious-analytics';
import { parseInternal } from '../utils/url';


export type LinkProps = {
  link: string;
  title?: string;
  children: ReactNode;
} & MuiLinkProps;


export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link({ link, title, children, ...rest }: LinkProps, ref) {

  const { track } = useAnalyticsQueued();
  const history = useHistory();

  let url: URL;
  try {
    url = new URL(link);
  } catch (e) {
    console.error('Invalid URL, using fallback to not crash app', link);
    url = new URL('https://app.icecream.club/');
  }

  const parsedLink = parseInternal(url.href);

  const handleClick = useCallback((ev: SyntheticEvent) => {
    if (!parsedLink.isInternal) {
      track('outbound', { category: 'link', origin: 'post' });
      if (Capacitor.isNativePlatform()) {
        ev.preventDefault();
        Browser.open({ url: link, presentationStyle: 'popover' });
      }
    } else {
      ev.preventDefault();
      ev.stopPropagation();
      history.push(`${url.pathname}?${url.searchParams}`);
    }
  }, [parsedLink.isInternal, track, link, history, url.pathname, url.searchParams]);

  return (
    <MuiLink {...rest} ref={ref} onClick={handleClick} target={parsedLink.isInternal ? undefined : '_blank'} rel={parsedLink.isInternal ? undefined : 'nofollow noreferrer'} title={title} href={link} underline="none">
      { children }
    </MuiLink>
  );
});
