import { CSSProperties, FC } from 'react';


export type HomeProps = {
  color?: string,
  style?: CSSProperties,
  selected?: boolean,
};


export const Home: FC<HomeProps> = ({ color='rgba(0, 0, 0, 0.87)', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 22"
    {...props}
    className={`home-icon ${props.selected ? 'selected' : ''}`}
    // eslint-disable-next-line react/prop-types
    style={{ ...props.style }}
  >
    <style >{`
        .home-icon .outline {
          stroke: ${color};
          opacity: 1;
          transition: all 0.20s;
        }
        .home-icon.selected .outline {
          opacity: 0;
        }
        .home-icon .fill {
          opacity: 0;
          fill: ${color};
          transition: all 0.20s;
        }
        .home-icon.selected .fill {
          opacity: 1;
        }

    `}</style>

    <defs/>
    <path className='outline' strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M1.9 6.6l6.75-5.15a2.24 2.24 0 012.7 0L18.1 6.6c.56.43.9 1.1.9 1.83V18.7c0 1.15 0 1.72-.29 2-.28.29-.84.29-1.96.29h-5.26l.01-.17V18.5h1a1 1 0 001-1v-6.14c0-.76-.37-1.49-1.03-2.02A3.94 3.94 0 0010 8.5c-.93 0-1.82.3-2.47.84a2.62 2.62 0 00-1.03 2.02v6.14a1 1 0 001 1h1V21H3.26c-1.12 0-1.68 0-1.96-.28-.29-.29-.29-.86-.29-2V8.41c0-.71.34-1.39.9-1.82z"/>
    <path className='fill' fillRule="evenodd" d="M8.2.86a2.99 2.99 0 013.6 0L18.56 6c.74.58 1.19 1.47 1.19 2.43v10.33c0 .53 0 1-.04 1.36-.04.37-.14.8-.47 1.13a1.8 1.8 0 01-1.11.46c-.36.04-.81.04-1.34.04h-5.3a.75.75 0 01-.74-.83V18.5c0-.41.34-.75.75-.75H13c.14 0 .25-.11.25-.25v-6.86a2.1 2.1 0 00-.88-1.63A3.86 3.86 0 0010 8.25c-.91 0-1.76.29-2.37.76a2.1 2.1 0 00-.88 1.63v6.86c0 .14.11.25.25.25h1.5c.41 0 .75.34.75.75v2.42a.75.75 0 01-.74.83H3.2c-.53 0-.98 0-1.34-.04a1.8 1.8 0 01-1.11-.46 1.8 1.8 0 01-.47-1.13c-.04-.36-.04-.83-.04-1.36V8.43c0-.96.45-1.85 1.2-2.43L8.2.86z" clipRule="evenodd"/>
  </svg>
);
