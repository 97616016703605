import { CSSProperties, FC } from 'react';


export type SavedProps = {
  color?: string,
  style?: CSSProperties,
  selected?: boolean,
};


export const Saved: FC<SavedProps> = ({ color='rgba(0, 0, 0, 0.87)', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 22"
    {...props}
    className={`saved-icon ${props.selected ? 'selected' : ''}`}
    // eslint-disable-next-line react/prop-types
    style={{ ...props.style }}
  >
    <style >{`
      .saved-icon .outline {
        fill: ${color};
        opacity: 1;
        transition: all 0.20s;
      }
      .saved-icon.selected .outline {
        opacity: 0;
      }
      .saved-icon .fill {
        opacity: 0;
        fill: ${color};
        transition: all 0.20s;
      }
      .saved-icon.selected .fill {
        opacity: 1;
      }
    `}</style>
    <defs/>
    <g className='outline'>
      <path d="M6.47 3.74a2.83 2.83 0 01.68-.16.5.5 0 00-.05-1l.03.5-.03-.5h-.03a2.49 2.49 0 00-.28.04 3.45 3.45 0 00-2.03 1.12v.01l.37.33-.38-.33a.5.5 0 00.75.66l.02-.01a2.16 2.16 0 01.26-.25c.19-.15.43-.32.7-.41z"/>
      <path fillRule="evenodd" d="M8 .25c-2.02 0-3.98.68-5.43 1.91A6.26 6.26 0 00.25 6.93v4.22a.75.75 0 00.98.72l14-4.46c.32-.1.53-.4.51-.74a6.3 6.3 0 00-2.3-4.5A8.44 8.44 0 008 .24zM3.54 3.31A6.94 6.94 0 018 1.75c1.7 0 3.3.57 4.46 1.56.95.8 1.53 1.8 1.72 2.86L1.75 10.13v-3.2c0-1.33.62-2.64 1.79-3.62zM15.75 9.85a.75.75 0 00-.98-.72l-14 4.46a.75.75 0 00-.52.71V20c0 1.35 1.48 2.2 2.64 1.5l4.98-2.95a.25.25 0 01.26 0l4.98 2.95c1.16.7 2.64-.15 2.64-1.5V9.85zM1.75 20v-5.15l12.5-3.98V20c0 .2-.21.31-.38.21L8.9 17.26a1.75 1.75 0 00-1.78 0l-4.98 2.95a.25.25 0 01-.38-.21z" clipRule="evenodd"/>
    </g>
    <g className='fill'>
      <path fillRule="evenodd" d="M2.57 2.16A8.44 8.44 0 018 .25c2.02 0 3.98.68 5.43 1.91a6.38 6.38 0 012.26 3.92.75.75 0 01-.52.83L1.23 11.34a.75.75 0 01-.98-.71v-3.7c0-1.82.85-3.53 2.32-4.77zm12.87 7.6c.2.15.31.37.31.61V20c0 1.35-1.48 2.2-2.64 1.5l-4.98-2.95a.25.25 0 00-.26 0L2.9 21.5A1.75 1.75 0 01.25 20v-5.17c0-.33.21-.62.52-.72l14-4.45a.75.75 0 01.67.1z" clipRule="evenodd"/>
    </g>
  </svg>
);
