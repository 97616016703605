import { FC, SyntheticEvent, useState } from 'react';
import { IconButton, SxProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Repeat } from 'react-feather';

import { Share } from '@/generated/graphql';
import { useSession } from '../../hooks/auth';
import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { useLoginPush } from '../../hooks/login-push';
import { MoreSheet } from './MoreSheet';


export type ReshareIconButtonProps = {
  share: Pick<Share, '_id'>,
  sx?: SxProps,
  showText?: boolean,
}


export const ReshareIconButton: FC<ReshareIconButtonProps> = function ReshareIconButton({ share, sx, showText=false }) {

  const theme = useTheme();
  const { user } = useSession();
  const { track } = useAnalyticsQueued();
  const { setLoginHint } = useLoginPush();

  const [sheetOpen, setSheetOpen] = useState(false);

  const onClick = (ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    if(user) {
      setSheetOpen(true);
      track('reshare_button', { category: 'share', origin: 'post' });
    } else {
      track('click_share_post', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in to share this.');
    }
  };

  const onClose = (ev?: SyntheticEvent) => {
    if(ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    setSheetOpen(false);
  };


  return (
    <>
      <IconButton
        aria-label="save"
        onClick={onClick}
        size="small"
        sx={{ height: 32, display: 'flex', flexDirection: 'row', p: 0, ...sx }}
      >
        <Repeat
          style={{
            stroke: theme.palette.grey[500],
            marginRight: 4,
            marginBottom: 1,
          }}
          width={14}
          height={14}
        />
        {showText &&
          <Typography variant='body2'>
            Share
          </Typography>
        }
      </IconButton>
      {sheetOpen &&
        <MoreSheet share={share} onClose={onClose} variant='share' />
      }
    </>
  );
}
