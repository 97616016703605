import { FC, MouseEvent, memo, useState } from 'react';
import { Box, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router';

import { CanonicalContent, Share } from '@/generated/graphql';
import { useSnackbar } from '@/hooks/snackbar';
import { useIsInWatchlist, useIsWatched, useToggleInWatchlist, useToggleWatched } from '@/hooks/watch-state';
import { SwipeableUnfurl } from '@/components/unfurl/SwipeableUnfurl';
import { Dialog, DialogProps } from '@/components/unfurl/Dialog';
import { ShareText, ShareTextProps } from '@/components/ShareText';
import { canonicalLink } from '@/utils/routes';
import { CompactUnfurl, CompactUnfurlProps } from '@/components/unfurl/CompactUnfurl';


interface MoreShareProps {
  share: Pick<Share, '_id'> & {
    message: Pick<Share['message'], 'link'>,
    canonicalContent?: Pick<CanonicalContent, '_id'> | null,
  } & CompactUnfurlProps['share'] & ShareTextProps['share'] & DialogProps['share'],
  canonicalContent?: Pick<CanonicalContent, '_id'> & CompactUnfurlProps['canonicalContent'] & DialogProps['canonicalContent'] | null,
}

export const MoreShare: FC<MoreShareProps> = memo(function MoreShare({ share, canonicalContent }: MoreShareProps) {

  const history = useHistory();
  const theme = useTheme();

  const { watchedSnackbar, watchlistSnackbar } = useSnackbar(share, canonicalContent);

  const isWatched = useIsWatched(share?._id, share?.canonicalContent?._id);
  const { toggleWatched } = useToggleWatched();
  const setIsWatched = (v: boolean) => toggleWatched(share?.canonicalContent?._id, share?._id, v);
  const isInWatchlist = useIsInWatchlist(share?._id, share?.canonicalContent?._id);
  const toggleInWatchlist = useToggleInWatchlist();
  const setIsInWatchlist = (v: boolean) => toggleInWatchlist(share?.canonicalContent?._id, share?._id, v);

  const [ dialogOpen, setDialogOpen ] = useState(false);

  const href = canonicalLink(canonicalContent?._id, share._id);

  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(href);
  };

  return (
    <Card
      component="a"
      href={href}
      onClick={handleClick}
      sx={{
        p: 1,
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        maxWidth: '100%',
        minWidth: 320,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'grey.300',
        borderRadius: 1,
      }}
    >

      <Box sx={{ flex: 1, flexDirection: 'column', pr: 1 }}>

        <ShareText share={share} link={false} color={theme.palette.background.paper} maxHeight={72} toggle={false} sx={{ mb: 0.5, minHeight: 72 }} />

        <Box sx={{ maxWidth: '280px' }}>
          <SwipeableUnfurl
            isWatched={isWatched}
            setIsWatched={(v: boolean) => {
              setIsWatched(v);
              if(v) {
                watchedSnackbar();
              }
            }}
            isInWatchlist={isInWatchlist}
            setIsInWatchlist={(v: boolean) => {
              setIsInWatchlist(v);
              if(v) {
                watchlistSnackbar(() => setDialogOpen(true));
              }
            }}
          >
            <CompactUnfurl
              share={share}
              canonicalContent={canonicalContent}
              sx={{ border: 'none', backgroundColor: 'grey.100' }}
              setDialogOpen={setDialogOpen}
            />
          </SwipeableUnfurl>
        </Box>
      </Box>
      {dialogOpen &&
        <Dialog share={share} canonicalContent={canonicalContent} open={dialogOpen} setOpen={setDialogOpen} />
      }
    </Card>
  );
});
