import { FC, SyntheticEvent, useEffect, useRef } from "react";
import { Box, Link, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { useTap } from "@/hooks/tap";
import { useTranslation, Trans } from '@/utils/i18n';
import { SwiperContainer, useSwiper } from "@/utils/swiper";
import { ExploreIllustration } from "@/components/icons/ExploreIllustration";
import { Mark } from "@/components/Mark";
import { Button } from "@/components/Button";
import Feed from '@/media/feed.png';
import Grid from '@/media/grid.png';
import Canonical from '@/media/canonical.png';
import { Router } from "./Container";


// Not exported because it should only be lazy loaded (using default import)
const WelcomeContent: FC<{ router: Router }> = function WelcomeContent({ router }) {

  const theme = useTheme();
  const { t } = useTranslation('auth');
  const swiperLoaded = useSwiper();

  const swiperRef = useRef<SwiperContainer>(null);

  useEffect(() => {
    if(!swiperLoaded) {
      console.log('Waiting for swiper to load');
      return;
    }
    if(!swiperRef.current?.initialize) {
      console.error('Swiper ref not set');
      return;
    }
    swiperRef.current.injectStyles = [
      `
      .swiper-pagination-bullet {
        color: ${theme.palette.primary.light};
        background-color: ${theme.palette.primary.light};
      }
      `,
    ];
    swiperRef.current.initialize();
  }, [theme, swiperLoaded]);

  const tap = useTap({ taps: 3, touches: 2 });

  return (
    <Box sx={{ mx: -1 }}>
      <style>{`
        @keyframes imgscroll {
          from {
            transform: translateY(0);
          }
          to {
            transform: translateY(calc(-1 * 100% + 200px));
          }
        }
      `}
      </style>

      <Typography variant="h1" sx={{ mb: 3, textAlign: 'center', fontWeight: 400 }}>
        {t('welcome_to')} <br /><Mark sx={{ fontWeight: 500, backgroundColor: 'primary.main', color: 'primary.contrastText', borderRadius: '4px' }}>&nbsp;Ice Cream Club&nbsp;</Mark>
      </Typography>

      <swiper-container
        ref={swiperRef}
        space-between={32}
        centered-slides={true}
        autoplay-delay="4000"
        autoplay-disable-on-interaction="true"
        pagination-clickable="true"
        init="false"
        events-prefix='swiper'
      >
        <swiper-slide>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 6, width: '100%' }}>
            <Typography variant="body1" sx={{ mr: 1.5, flex: 1 }}>
              <Trans ns="auth" i18nKey="best_place">
                best_place
              </Trans>
              <br />
              <Trans ns="auth" i18nKey="together_with<1>friends</1>">
                together_with<Mark sx={{ fontWeight: 500, backgroundColor: 'primary.light', color: 'primary.contrastText', borderRadius: '2px', whiteSpace: 'nowrap', px: '6px' }}>friends.</Mark>
              </Trans>
            </Typography>
            <ExploreIllustration onTouchStart={tap((ev: SyntheticEvent) => {
              ev.preventDefault();
              ev.stopPropagation();
              router('test');
            })} color={theme.palette.primary.light} spaceshipColor={theme.palette.primary.main} width="80px" height="100%" />
          </Box>
        </swiper-slide>
        <swiper-slide>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 6, width: '100%', gap: 1 }}>
            <Box sx={{ height: 200, overflow: 'hidden' }}>
              <img src={Feed} alt='Feed of friends shared movies' style={{ width: '100%', maxWidth: '130px', borderRadius: '8px', animation: '7s imgscroll ease-in-out infinite alternate' }} />
            </Box>
            <Typography variant="body1" sx={{ flex: 1 }}>
              <Trans ns="auth" i18nKey="do_you_want">
                do_you_want
              </Trans>
              <br />
              <Trans ns="auth" i18nKey="your_friends_want<1>your</1>">
                your_friends_want<Mark sx={{ fontWeight: 500, backgroundColor: 'primary.light', color: 'primary.contrastText', borderRadius: '2px', whiteSpace: 'nowrap', px: '6px' }}>your</Mark>recommendations
              </Trans>
            </Typography>
          </Box>
        </swiper-slide>
        <swiper-slide>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 6, width: '100%', gap: 1 }}>
            <Typography variant="body1" sx={{ flex: 1 }}>
              <Trans ns="auth" i18nKey="remember<1>remember</1>that_recommendation">
                remember<Mark sx={{ fontWeight: 500, backgroundColor: 'primary.light', color: 'primary.contrastText', borderRadius: '2px', px: '6px' }}>remember</Mark>that_recommendation
              </Trans>
            </Typography>
            <Box sx={{ height: 200, overflow: 'hidden' }}>
              <img src={Grid} alt='Collections of what interests you' style={{ width: '100%', maxWidth: '130px', borderRadius: '8px', animation: '7s imgscroll ease-in-out infinite alternate' }} />
            </Box>
          </Box>
        </swiper-slide>
        <swiper-slide>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 6, width: '100%', gap: 1 }}>
            <Box sx={{ height: 200, overflow: 'hidden' }}>
              <img src={Canonical} alt='Screen with movie info' style={{ width: '100%', maxWidth: '130px', borderRadius: '8px', animation: '7s imgscroll ease-in-out infinite alternate' }} />
            </Box>
            <Typography variant="body1" sx={{ mr: 1.5, flex: 1 }}>
              <Trans ns="auth" i18nKey="stay_up_to_date<1>trust</1>">
                stay_up_to_date<Mark sx={{ fontWeight: 500, backgroundColor: 'primary.light', color: 'primary.contrastText', borderRadius: '2px', whiteSpace: 'nowrap', px: '6px' }}>trust</Mark>
              </Trans>
            </Typography>
          </Box>
        </swiper-slide>
      </swiper-container>

      <Button
        variant="contained"
        color="primary"
        sx={{ my: 2 }}
        fullWidth
        onClick={() => router('register')}
      >
        {t('create_account')}
      </Button>

      <Typography sx={{ mb: 2, textAlign: 'center' }}>
        {t('already_a_member')}{' '}
        <Link
          color="text.primary"
          fontWeight="500"
          onClick={() => router('login')}
          data-cy='login-continue'
        >
          {t('log_in')}
        </Link>
      </Typography>

      {/* <Typography sx={{ mb: 2, textAlign: 'center' }}>
        <Link color="#fff" onClick={() => setDisplayState('hidden')} data-cy='login-continue'>
          Continue as guest
        </Link>
      </Typography> */}

      {/* {'testing' &&
        <Typography sx={{ mb: 2, textAlign: 'center' }}>
          Testing! <Link color="#fff" fontWeight="500" onClick={() => router('test')}>Test</Link>
        </Typography>
      } */}
    </Box>
  );
};

export default WelcomeContent;
