import { FC, memo, SyntheticEvent } from 'react';
import { Box, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { CanonicalContent, Contact, Context, Message, Rating, Share } from '@/generated/graphql';
import { useIsInWatchlist, useIsWatched, useToggleInWatchlist, useToggleWatched } from '@/hooks/watch-state';
import { useSnackbar } from '@/hooks/snackbar';
import { testIccCollection } from '@/utils/url';
import { UserName } from '@/components/UserName';
import { SwipeableUnfurl } from '@/components/unfurl/SwipeableUnfurl';
import { CompactShareUnfurl, CompactShareUnfurlProps } from '@/components/unfurl/CompactShareUnfurl';
import { FollowButton } from '@/components/FollowButton';
import { ListShare, ListShareProps } from '@/components/Post/ListShare';
import { Avatar } from '@/components/Avatar';
import { ShareContext } from '@/components/ShareContext';
import { ShareText, ShareTextProps } from '@/components/ShareText';
import { RatingIcon } from '@/components/icons/RatingIcon';
import { ChatOverlay, ChatOverlayProps } from '@/components/ChatOverlay';


export type ShareChatOverlayProps = {
  comments: ChatOverlayProps['comments'],
  share: Pick<Share, '_id'> & {
    canonicalContent?: Pick<CanonicalContent, '_id'> | null,
    context?: Pick<Context, 'type'> | null,
    message?: Pick<Message, 'link'> | null,
    sender?: Pick<Contact, '_id'> | null,
    sendersRating?: Pick<Rating, 'rating'> | null,
    list?: ListShareProps['list'] | null,
  } & ChatOverlayProps['share'] & ShareTextProps['share'] & CompactShareUnfurlProps['share'],
  canonicalContent?: Pick<CanonicalContent, '_id'> & CompactShareUnfurlProps['canonicalContent'] | null,
  setDialogOpen?: (v: boolean) => void,
  onClose: () => void,
  contextColor: ChatOverlayProps['contextColor'],
}

export const ShareChatOverlay: FC<ShareChatOverlayProps> = memo(function ShareChatOverlay({ comments, share, canonicalContent, setDialogOpen, onClose, contextColor }: ShareChatOverlayProps) {

  const theme = useTheme();

  const { watchedSnackbar, watchlistSnackbar } = useSnackbar(share, canonicalContent);

  const isWatched = useIsWatched(share?._id, share?.canonicalContent?._id);
  const { toggleWatched } = useToggleWatched();
  const setIsWatched = (v: boolean) => toggleWatched(share?.canonicalContent?._id, share?._id, v);
  const isInWatchlist = useIsInWatchlist(share?._id, share?.canonicalContent?._id);
  const toggleInWatchlist = useToggleInWatchlist();
  const setIsInWatchlist = (v: boolean) => toggleInWatchlist(share?.canonicalContent?._id, share?._id, v);

  return (
    <ChatOverlay comments={comments || []} share={share} onClose={(ev) => { ev?.preventDefault(); ev?.stopPropagation(); onClose() }} contextColor={contextColor}>
      <Card
        sx={{
          position: 'relative',
          mx: 2,
          p: 1,
          mb: 1,
          mt: 5,
          '&:last-of-type': { mb: 0 },
          borderRadius: '8px',
          backgroundColor: 'background.paper',
          border: `1px solid ${theme.palette.grey['100']}`,
          overflow: 'unset',
          boxShadow: 1,
        }}
        onClick={(ev: SyntheticEvent) => ev.stopPropagation() }
      >
        <Box sx={{ display: 'flex', mb: 1, mt: 0.5, gap: 1 }}>
          <Avatar
            sx={{ flex: '0 0 32px' }}
            size={'medium'}
            contact={share.sender?._id}
            link={true}
          />

          {share.sender &&
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <UserName
                userId={share.sender?._id}
                addLink={true}
                postfix=' shared'
                sx={{ fontWeight: 500, flex: '0 0 auto' }}
              />
              {share.sendersRating &&
                <RatingIcon value={share.sendersRating.rating} highlight />
              }
              {share.context && <ShareContext context={share.context} />}
              <Box sx={{ flex: '1 1 auto' }}>
                <FollowButton contact={share.sender} lineHeight='20px' divider={false} />
              </Box>
            </Box>
          }
        </Box>

        <Box sx={{ pb: 0.5 }}>
          <ShareText share={share} link={false} color={theme.palette.background.paper} maxHeight={9999} toggle={false} />
        </Box>

        <Box sx={{ maxWidth: '280px', my: 1 }}>
          {share.message?.link && share.list && testIccCollection(share.message.link) ? (
            <ListShare list={share.list} sx={{ border: 'none', backgroundColor: 'grey.100' }} link={`/post/${share._id}/`} showUser={share.list.createdBy?._id !== share.sender?._id} />
          ) : (
            <SwipeableUnfurl
              isWatched={isWatched}
              setIsWatched={(v) => {
                setIsWatched(v);
                if(v) {
                  watchedSnackbar();
                }
              }}
              isInWatchlist={isInWatchlist}
              setIsInWatchlist={(v) => {
                setIsInWatchlist(v);
                if(v && setDialogOpen) {
                  watchlistSnackbar(() => setDialogOpen(true));
                }
              }}
            >
              <CompactShareUnfurl
                share={share}
                canonicalContent={canonicalContent}
                sx={{ border: 'none', backgroundColor: 'grey.100' }}
                setDialogOpen={setDialogOpen}
              />
            </SwipeableUnfurl>
          )}
        </Box>

      </Card>
    </ChatOverlay>
  );
});
