import { DOMAttributes, RefAttributes, useEffect, useState } from 'react';


type CustomEvents<K extends string> = { [key in K] : (event: CustomEvent) => void };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CustomElement<T, K extends string> = Partial<T & DOMAttributes<T> & { children: any } & CustomEvents<`on${K}`>>;


type SwiperContainerCore = CustomElement<Omit<HTMLElement, 'style'> & {
  style: Partial<CSSStyleDeclaration>,
  'space-between'?: number,
  'centered-slides'?: boolean,
  autoplay?: string,
  pagination?: string,
  init?: string,
  injectStyles?: string[],
  initialize?: () => void,
  swiper?: any, // eslint-disable-line @typescript-eslint/no-explicit-any
}, 'update'>;

export type SwiperContainer = SwiperContainerCore & RefAttributes<SwiperContainerCore>;


export type SwiperSlide = CustomElement<HTMLElement & {
  key: string | number | undefined | null,
}, 'update'>;


declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      ['swiper-container']: SwiperContainer;
      ['swiper-slide']: SwiperSlide;
    }
  }
}


export function initialize(): Promise<void> {
  return import('swiper/element/bundle')
    .then((el) => {
      console.log('register swiper', el);
      el.register();
    });
}


export function useSwiper() {
  const [swiperLoaded, setSwiperLoaded] = useState(false);
  useEffect(() => {
    initialize().then(() => setSwiperLoaded(true));
  }, []);
  return swiperLoaded;
}


export {};
