import pluralize from "pluralize";
import { Avatar, CanonicalContent, Contact, Image, List, Movie, Share, TvShow, Video } from "../generated/graphql";
import { canonicalLink } from "./routes";
import { createVideoThumbnailUrl, createVideoUrl } from "./video";


type ContactType = Pick<Contact, '_id' | 'username' | 'name' | 'bio'> & {
  avatar?: Pick<Avatar, 'url'> | null,
  stats?: {
    leads?: number | null,
    follows?: number | null,
    shares?: number | null,
    comments?: number | null,
    commentsOnShares?: number | null,
    reactions?: number | null,
    reactionsOnShares?: number | null,
    collectionsPrivate?: number | null,
    collectionsPublic?: number | null,
    followedCollections?: number | null,
    leadsCollections?: number | null,
  } | null
};


type ProfessionalType = {
  __typename?: string,
  _id: string,
  professional?: string | null,
  name: string,
  biography?: string | null,
  birthday?: string | null,
  deathday?: string | null,
  knownForDepartment?: string | null,
  gender?: string | null,
  profileImage?: Pick<Image, 'hash'> | null,
  tmdb?: string | null,
  imdb?: string | null,
  homepage?: string | null,
};


type MovieType = Pick<CanonicalContent, '_id' | 'createdAt'> & {
  type: 'movie',
  movie: Pick<Movie, 'title' | 'overview' | 'genres' | 'release' | 'video' | 'imdb' | 'tmdb' | 'homepage' | 'imdbRating' | 'imdbVotes' | 'year' | 'originalLanguage' | 'originalTitle'> & {
    poster?: Pick<Image, 'hash'> | null,
    directors: Array<ProfessionalType>,
  }
};


type TvShowType = Pick<CanonicalContent, '_id' | 'createdAt'> & {
  type: 'tvshow',
  tvshow: Pick<TvShow, 'title' | 'overview' | 'genres' | 'firstAirDate' | 'video' | 'imdb' | 'tmdb' | 'homepage' | 'imdbRating' | 'imdbVotes' | 'startYear' | 'originalLanguage' | 'originalTitle'> & {
    poster?: Pick<Image, 'hash'> | null,
    directors: Array<ProfessionalType>,
    createdBy: Array<ProfessionalType>,
  }
};


type CanonicalType = Pick<CanonicalContent, '_id' | 'type' | 'createdAt'> & {
  movie?: MovieType['movie'] | null,
  tvshow?: TvShowType['tvshow'] | null,
};

function isMovieType(canonical: CanonicalType): canonical is MovieType { return canonical.type === 'movie'; }
function isTvShowType(canonical: CanonicalType): canonical is TvShowType { return canonical.type === 'tvshow'; }


type ShareType = Pick<Share, '_id'> & {
  message?: {
    text?: string
    language?: string
  } | null,
  unfurl: {
    title?: string | null,
    link?: string | null,
  },
  createdAt?: string,
  comments?: Array<{
    text?: string,
    language?: string,
    createdAt?: string,
    author?: ContactType | null,
  }>,
};


type CollectionType = Pick<List, '_id' | 'name' | 'description'>;


type CollectionItemsType = Array<{
  share?: ShareType & {
    canonicalContent?: CanonicalType | null;
    sender?: ContactType | null,
  } | null,
  canonicalContent?: CanonicalType | null,
}>;



type CollectionsType = Array<Pick<List, '_id' | 'name' | 'description'>>;


type WatchlistType = Array<{
  share?: ShareType & {
    canonicalContent?: Pick<CanonicalContent, '_id'> | null,
    sender?: ContactType | null,
  } | null,
  canonicalContent?: CanonicalType | null,
}>;


function createCanonicalBreadcrumbs(canonicalContent: CanonicalType, share?: ShareType, createdBy?: ContactType) {
  const title = canonicalContent.movie?.title || canonicalContent.tvshow?.title;

  return [
    {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://app.icecream.club"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": title,
          "item": `https://app.icecream.club${canonicalLink(canonicalContent?._id, undefined)}`
        },
        (share && createdBy) && {
          "@type": "ListItem",
          "position": 3,
          "name": `by @${createdBy?.username}`,
          "item": `https://app.icecream.club${canonicalLink(canonicalContent?._id, share._id)}`
        }
      ].filter(Boolean)
    },
    {
      "@type": "BreadcrumbList",
      "name": "Site index by year",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://app.icecream.club"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Index",
          "item": "https://app.icecream.club/siteindex/"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": `${pluralize(canonicalContent.type)}`,
          "item": `https://app.icecream.club/siteindex/${canonicalContent.type}s/`
        },
        (canonicalContent.movie?.year || canonicalContent.tvshow?.startYear) && {
          "@type": "ListItem",
          "position": 4,
          "name": canonicalContent.movie?.year || canonicalContent.tvshow?.startYear,
          "item": `https://app.icecream.club/siteindex/${canonicalContent.type}s/by-year/${canonicalContent.movie?.year || canonicalContent.tvshow?.startYear}/`
        },
        {
          "@type": "ListItem",
          "position": 5,
          "name": title,
          "item": `https://app.icecream.club${canonicalLink(canonicalContent?._id, undefined)}`
        },
        (share && createdBy) && {
          "@type": "ListItem",
          "position": 6,
          "name": `by @${createdBy?.username}`,
          "item": `https://app.icecream.club${canonicalLink(canonicalContent?._id, share._id)}`
        }
      ].filter(Boolean)
    },
    {
      "@type": "BreadcrumbList",
      "name": "Site index by genre",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://app.icecream.club"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Index",
          "item": "https://app.icecream.club/siteindex/"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": `${pluralize(canonicalContent.type)}`,
          "item": `https://app.icecream.club/siteindex/${canonicalContent.type}s/`
        },
        {
          "@type": "ListItem",
          "position": 4,
          "name": `${canonicalContent.movie?.genres?.[0] || canonicalContent.tvshow?.genres?.[0]}`,
          "item": `https://app.icecream.club/siteindex/${canonicalContent.type}s/by-genre/${canonicalContent.movie?.genres?.[0] || canonicalContent.tvshow?.genres?.[0]}/`
        },
        {
          "@type": "ListItem",
          "position": 5,
          "name": title,
          "item": `https://app.icecream.club${canonicalLink(canonicalContent?._id, undefined)}`
        },
        (share && createdBy) && {
          "@type": "ListItem",
          "position": 6,
          "name": `by @${createdBy?.username}`,
          "item": `https://app.icecream.club${canonicalLink(canonicalContent?._id, share._id)}`
        }
      ].filter(Boolean)
    },
    {
      "@type": "BreadcrumbList",
      "name": "Site index by language",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://app.icecream.club"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Index",
          "item": "https://app.icecream.club/siteindex/"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": `${pluralize(canonicalContent.type)}`,
          "item": `https://app.icecream.club/siteindex/${canonicalContent.type}s/`
        },
        {
          "@type": "ListItem",
          "position": 4,
          "name": `${canonicalContent.movie?.originalLanguage || canonicalContent.tvshow?.originalLanguage}`,
          "item": `https://app.icecream.club/siteindex/${canonicalContent.type}s/by-language/${canonicalContent.movie?.originalLanguage || canonicalContent.tvshow?.originalLanguage}/`
        },
        {
          "@type": "ListItem",
          "position": 5,
          "name": title,
          "item": `https://app.icecream.club${canonicalLink(canonicalContent._id, undefined)}`
        },
        (share && createdBy) && {
          "@type": "ListItem",
          "position": 6,
          "name": `by @${createdBy?.username}`,
          "item": `https://app.icecream.club${canonicalLink(canonicalContent?._id, share._id)}`
        }
      ].filter(Boolean)
    },
  ];
}


function createUncategorizedBreadcrumbs(share: ShareType, createdBy?: ContactType) {
  return [
    {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://app.icecream.club"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": `@${createdBy?.username}`,
          "item": `https://app.icecream.club/@${createdBy?.username}/`
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": `by @${share.unfurl.title}`,
          "item": `https://app.icecream.club${canonicalLink(undefined, share._id)}`
        }
      ],
    },
  ];
}


export function createShareType(share: ShareType, canonicalContent: CanonicalType | undefined | null, createdBy?: ContactType | null) {
  return {
    "@context": "https://schema.org/",
    "@type": "SocialMediaPosting",
    "@id": `https://app.icecream.club${canonicalLink(canonicalContent?._id, share._id)}#SocialMediaPosting`,
    "url": `https://app.icecream.club${canonicalLink(canonicalContent?._id, share._id)}`,
    "author": createdBy && createContactType(createdBy),
    "articleBody": share.message?.text,
    "inLanguage": share.message?.language,
    "dateCreated": share.createdAt,
    "sharedContent": canonicalContent && createCanonicalType(canonicalContent),
    "comment": share.comments?.map(comment => (
      {
        "@type": "Comment",
        "text": comment.text,
        "inLanguage": comment.language,
        "dateCreated": comment.createdAt,
        "author": comment.author && createContactType(comment.author),
      }
    )),
  };
}


export function createSharePageType(share: ShareType, canonicalContent: CanonicalType | undefined | null, createdBy?: ContactType | null) {
  const title = canonicalContent?.movie?.title || canonicalContent?.tvshow?.title || share.unfurl.title;
  return {
    "@context": "https://schema.org/",
    "@type": "WebPage",
    "@id": `https://app.icecream.club${canonicalLink(canonicalContent?._id, share._id)}`,
    "url": `https://app.icecream.club${canonicalLink(canonicalContent?._id, share._id)}`,
    "name": title,
    "description": `@${createdBy?.username} shared ${title}`,
    "breadcrumb": canonicalContent ? createCanonicalBreadcrumbs(canonicalContent, share) : createUncategorizedBreadcrumbs(share),
    "mainEntity": createShareType(share, canonicalContent, createdBy),
  };
}


export function createCanonicalPageType(canonicalContent: CanonicalType) {
  const title = canonicalContent?.movie?.title || canonicalContent?.tvshow?.title;
  return {
    "@context": "https://schema.org/",
    "@type": "WebPage",
    "@id": `https://app.icecream.club${canonicalLink(canonicalContent?._id, undefined)}`,
    "url": `https://app.icecream.club${canonicalLink(canonicalContent?._id, undefined)}`,
    "name": title,
    "description": `${title}`,
    "breadcrumb": createCanonicalBreadcrumbs(canonicalContent),
    "mainEntity": createCanonicalType(canonicalContent),
  };
}


export function createMovieType(canonical: MovieType) {
  const movie = canonical.movie;
  const imageUrl = movie.poster ? `${process.env.REACT_APP_IMAGE_BASE}/${movie.poster.hash}_512x512.jpg` : undefined;
  return {
    "@context": "https://schema.org/",
    "@type": "Movie",
    "@id": `https://app.icecream.club${canonicalLink(canonical._id)}`,
    "url": `https://app.icecream.club${canonicalLink(canonical._id)}`,
    "name": movie.originalTitle,
    "alternateName": movie.title !== movie.originalTitle ? movie.title : undefined,
    "inLanguage": movie.originalLanguage,
    "description": movie.overview,
    "image": imageUrl,
    "genre": movie.genres,
    "datePublished": movie.release,
    "director": movie.directors.map(createProfessionalType),
    "trailer": movie.video && createVideoType(movie.video, canonical.createdAt),
    "aggregateRating": movie.imdbRating && {
      "@type": "AggregateRating",
      "bestRating": "10",
      "worstRating": "1",
      "ratingValue": movie.imdbRating,
      "ratingCount": movie.imdbVotes,
    },
    "sameAs": [
      movie.tmdb && `https://www.themoviedb.org/movie/${movie.tmdb}`,
      movie.imdb && `https://www.imdb.com/title/${movie.imdb}/`,
      movie.homepage,
    ].filter(Boolean),
  };
}


export function createTvShowType(canonical: TvShowType) {
  const tvshow = canonical.tvshow;
  const imageUrl = tvshow.poster ? `${process.env.REACT_APP_IMAGE_BASE}/${tvshow.poster.hash}_512x512.jpg` : undefined;
  return {
    "@context": "https://schema.org/",
    "@type": "TVSeries",
    "@id": `https://app.icecream.club${canonicalLink(canonical._id)}`,
    "url": `https://app.icecream.club${canonicalLink(canonical._id)}`,
    "name": tvshow.originalTitle,
    "alternateName": tvshow.title !== tvshow.originalTitle ? tvshow.title : undefined,
    "inLanguage": tvshow.originalLanguage,
    "description": tvshow.overview,
    "image": imageUrl,
    "genre": tvshow.genres,
    "datePublished": tvshow.firstAirDate,
    "creator": tvshow.createdBy.map(createProfessionalType).filter(Boolean),
    "director": tvshow.directors.map(createProfessionalType).filter(Boolean),
    "trailer": tvshow.video && createVideoType(tvshow.video, canonical.createdAt),
    "aggregateRating": tvshow.imdbRating && {
      "@type": "AggregateRating",
      "bestRating": "10",
      "worstRating": "1",
      "ratingValue": tvshow.imdbRating,
      "ratingCount": tvshow.imdbVotes,
    },
    "sameAs": [
      tvshow.tmdb && `https://www.themoviedb.org/tv/${tvshow.tmdb}`,
      tvshow.imdb && `https://www.imdb.com/title/${tvshow.imdb}/`,
      tvshow.homepage,
    ].filter(Boolean),
  };
}


export function createCanonicalType(canonicalContent: CanonicalType) {
  if(isMovieType(canonicalContent)) {
    return createMovieType(canonicalContent);
  } else if(isTvShowType(canonicalContent)) {
    return createTvShowType(canonicalContent);
  }
}


export function createProfessionalType(professional: ProfessionalType) {
  const id = professional.__typename === 'Professional' ? professional._id : professional.professional;
  if(!id) {
    return null;
  }
  return {
    "@context": "https://schema.org/",
    "@type": "Person",
    "@id": `https://app.icecream.club/professional/${id}#Person`,
    "url": `https://app.icecream.club/professional/${id}`,
    "name": professional.name,
    "description": professional.biography,
    "birthDate": professional.birthday,
    "deathDate": professional.deathday,
    "jobTitle": professional.knownForDepartment,
    "gender": professional.gender,
    "image": professional.profileImage && `${process.env.REACT_APP_IMAGE_BASE}/${professional.profileImage.hash}_512x512.jpg`,
    "sameAs": [
      professional.tmdb && `https://www.themoviedb.org/person/${professional.tmdb}`,
      professional.imdb && `https://www.imdb.com/name/${professional.imdb}/`,
      professional.homepage,
    ].filter(Boolean),
  };
}


export function createProfessionalPageType(professional: ProfessionalType) {
  return {
    "@context": "https://schema.org/",
    "@type": "ProfilePage",
    "@id": `https://app.icecream.club/professional/${professional._id}`,
    "url": `https://app.icecream.club/professional/${professional._id}`,
    "name": professional.name,
    "mainEntity": createProfessionalType(professional),
  };
}


export function createContactType(contact: ContactType) {
  return {
    "@context": "https://schema.org/",
    "@type": "Person",
    "@id": `https://app.icecream.club/profile-id/${contact._id}/#Person`,
    "url": contact.username && `https://app.icecream.club/@${contact.username}`,
    "name": contact.name,
    "alternateName": contact.username,
    "description": contact.bio,
    "image": contact.avatar?.url,
    "identifier": contact._id,
    "agentInteractionStatistic": contact.stats && [
      Number.isFinite(contact.stats.follows) && {
        "@type": "InteractionCounter",
        "interactionType": "https://schema.org/FollowAction",
        "userInteractionCount": contact.stats.follows
      },
      Number.isFinite(contact.stats.reactions) && {
        "@type": "InteractionCounter",
        "interactionType": "https://schema.org/LikeAction",
        "userInteractionCount": contact.stats.reactions
      },
      (Number.isFinite(contact.stats.comments) || Number.isFinite(contact.stats.shares)) && {
        "@type": "InteractionCounter",
        "interactionType": "https://schema.org/WriteAction",
        "userInteractionCount": (contact.stats.comments || 0) + (contact.stats.shares || 0)
      },
    ],
    "interactionStatistic": contact.stats && [
      Number.isFinite(contact.stats.leads) && {
        "@type": "InteractionCounter",
        "interactionType": "https://schema.org/FollowAction",
        "userInteractionCount": contact.stats.leads
      },
    ],
  };
}


export function createCollectionPageType(collection: CollectionType, collectionItems: CollectionItemsType, createdBy: ContactType) {
  return {
    "@context": "https://schema.org/",
    "@type": "CollectionPage",
    "@id": `https://app.icecream.club/@${createdBy.username}/collections/${collection._id}`,
    "url": `https://app.icecream.club/@${createdBy.username}/collections/${collection._id}`,
    "name": `${collection.name} - A collection by @${createdBy?.username}`,
    "description": collection.description,
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://app.icecream.club"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": createdBy.name,
          "item": `https://app.icecream.club/@${createdBy.username}`
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": `Collections`,
          "item": `https://app.icecream.club/@${createdBy.username}/collections`
        },
        {
          "@type": "ListItem",
          "position": 4,
          "name": collection.name,
          "item": `https://app.icecream.club/@${createdBy.username}/collections/${collection._id}`
        }
      ],
    },
    "mainEntity": createCollectionType(collection, collectionItems, createdBy),
  }
}


export function createCollectionType(collection: CollectionType, collectionItems: CollectionItemsType, createdBy: ContactType) {
  return {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "@id": `https://app.icecream.club/@${createdBy.username}/collections/${collection._id}#ItemList`,
    "url": `https://app.icecream.club/@${createdBy.username}/collections/${collection._id}`,
    "name": collection.name,
    "description": collection.description,
    "itemListElement": collectionItems.map(item => {
      let canonical = item.canonicalContent || item.share?.canonicalContent;
      if(canonical && canonical.type !== 'movie' && canonical.type !== 'tvshow') {
        canonical = undefined;
      }
      if(item.share) {
        return createShareType(item.share, canonical, item.share.sender);
      } else if(canonical) {
        return createCanonicalType(canonical);
      }
      return null;
    }).filter(Boolean),
  };
}


export function createWatchlistType(watchlist: WatchlistType, createdBy: ContactType) {
  return {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "@id": `https://app.icecream.club/@${createdBy.username}/watchlist#ItemList`,
    "url": `https://app.icecream.club/@${createdBy.username}/watchlist`,
    "name": `@${createdBy?.username}${createdBy?.username?.endsWith('s') ? '' : "'s"} Watchlist`,
    "itemListElement": watchlist.map(item => {
      let canonical = item.canonicalContent;
      if(canonical && canonical.type !== 'movie' && canonical.type !== 'tvshow') {
        canonical = undefined;
      }
      if(item.share) {
        return createShareType(item.share, canonical, item.share.sender);
      } else if(canonical) {
        return createCanonicalType(canonical);
      }
      return null;
    }).filter(Boolean),
  };
}


export function createWatchlistPageType(watchlist: WatchlistType, createdBy: ContactType) {
  return {
    "@context": "https://schema.org/",
    "@type": "CollectionPage",
    "@id": `https://app.icecream.club/@${createdBy.username}/watchlist`,
    "url": `https://app.icecream.club/@${createdBy.username}/watchlist`,
    "name": `@${createdBy.username}${createdBy.username?.endsWith('s') ? '' : "'s"} Watchlist`,
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://app.icecream.club"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": createdBy.name,
          "item": `https://app.icecream.club/@${createdBy.username}`
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": `Watchlist`,
          "item": `https://app.icecream.club/@${createdBy.username}/watchlist`
        }
      ],
    },
    "mainEntity": createWatchlistType(watchlist, createdBy),
  }
}


export function createVideoType(video: Pick<Video, 'title' | 'site' | 'key'>, createdAt: string) {
  return {
    "@context": "https://schema.org/",
    "@type": "VideoObject",
    "url": createVideoUrl(video),
    "name": video.title || 'Trailer',
    "thumbnailUrl": createVideoThumbnailUrl(video),
    "uploadDate": createdAt,
  };
}


export function createPerformanceType(performances: Array<ProfessionalType & { character: string }>, canonicalId: string) {
  return {
    "@context": "https://schema.org/",
    "@id": `https://app.icecream.club${canonicalLink(canonicalId)}`,
    "actor": performances.filter(c => c.professional).map((actor) => (
      {
        "@type": "PerformanceRole",
        "characterName": actor.character,
        "actor": createProfessionalType(actor),
      }
    )),
  };
}


export function createProfilePageType(contact: ContactType) {
  return {
    "@context": "https://schema.org/",
    "@type": "ProfilePage",
    "@id": `https://app.icecream.club/@${contact.username}`,
    "url": `https://app.icecream.club/@${contact.username}`,
    "mainEntity": createContactType(contact),
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://app.icecream.club"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": contact.name,
          "item": `https://app.icecream.club/@${contact.username}`
        },
      ].filter(Boolean),
    }
  }
}


export function createCollectionsPageType(contact: ContactType, collections: CollectionsType) {
  return {
    "@context": "https://schema.org/",
    "@type": "CollectionPage",
    "@id": `https://app.icecream.club/@${contact.username}/collections`,
    "url": `https://app.icecream.club/@${contact.username}/collections`,
    "name": `@${contact.username}${contact.username?.endsWith('s') ? '' : "'s"} Collections`,
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://app.icecream.club"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": contact.name,
          "item": `https://app.icecream.club/@${contact.username}`
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": `Collections`,
          "item": `https://app.icecream.club/@${contact.username}/collections`
        }
      ],
    },
    "mainEntity": createCollectionsType(contact, collections),
  }
}


export function createCollectionsType(contact: ContactType, collections: CollectionsType) {
  return {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "@id": `https://app.icecream.club/@${contact.username}/collections#ItemList`,
    "url": `https://app.icecream.club/@${contact.username}/collections`,
    "name": `@${contact.username}${contact.username?.endsWith('s') ? '' : "'s"} Collections`,
    "itemListElement": collections.map((collection, i) => {
      return {
        "@type": "ListItem",
        "position": i,
        "item": {
          "@type": "CollectionPage",
          "@id": `https://app.icecream.club/@${contact.username}/collections/${collection._id}`,
          "url": `https://app.icecream.club/@${contact.username}/collections/${collection._id}`,
          "name": collection.name,
          "description": collection.description,
        }
      };
    }).filter(Boolean),
  };
}


export function createWebSiteType() {
  return {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": "Ice Cream Club",
    "url": "https://app.icecream.club",
    "potentialAction": {
      "@type": "SearchAction",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": "https://app.icecream.club/search?q={search_term_string}"
      },
      "query-input": "required name=search_term_string"
    }
  };
}
