import { FC, SyntheticEvent, useCallback, useState } from 'react';
import { Typography, Button, Link, Divider } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import * as EmailValidator from 'email-validator';
import { signInWithEmailAndPassword, getAdditionalUserInfo } from 'firebase/auth';
import { Capacitor } from '@capacitor/core';
import { Apple, Twitter, Facebook, Email } from '@mui/icons-material';

import { firebaseAuth } from '../../firebase';
import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { useSnackbar } from '@/hooks/snackbar';
import { useTranslation } from '../../utils/i18n';
import { isMacLike } from '../../utils/detect';
import { EmailInput, PasswordInput, AppleButton, GoogleButton, TwitterButton, FacebookButton } from '../../components/auth';
import { Router } from './Container';

const errorMessages = {
  'auth/wrong-password': 'Password or email is wrong',
  'auth/user-not-found': 'Email is not registered',
};

export const Login: FC<{ router: Router, email: string, setEmail: (email: string) => void, password: string, setPassword: (password: string) => void }> = function Login({ router, email, setEmail, password, setPassword }) {

  const { track } = useAnalyticsQueued();
  const [ loading, setLoading ] = useState<boolean>();
  const [ showMore, setShowMore ] = useState<boolean>(false);
  const { show } = useSnackbar();
  const { t } = useTranslation('auth');

  const login = useCallback((ev: SyntheticEvent) => {
    ev.preventDefault();
    setLoading(true);
    if (email && password) {
      track('login_submit', { category: 'auth', action: 'login', provider: 'email-and-password' });
      signInWithEmailAndPassword(firebaseAuth, email, password)
        .then((result) => {
          // We should have successful auth
          const userInfo = getAdditionalUserInfo(result);
          if (userInfo?.isNewUser) {
            track('signup', { category: 'auth', action: 'login', provider: 'email-and-password' });
          } else {
            track('login', { category: 'auth', action: 'login', provider: 'email-and-password' });
          }
        })
        .catch((e) => {
          const code: string = e.code || '';
          track('auth_error', { category: 'auth', code: code, error: e.message });
          if(code in errorMessages) {
            show({ text: errorMessages[code as keyof typeof errorMessages], severity: 'error' });
          } else {
            console.error(e);
            show({ text: 'Something went wrong, try again', severity: 'error' });
          }
          setLoading(false);
        });
    } else {
      track('auth_error', { category: 'auth', code: 'missing-email-or-password', error: 'Missing email or password' });
      show({ text: 'Missing email or password', severity: 'error' });
      setLoading(false);
    }
  }, [ show, track, email, password ]);


  const handleClickShowMore = useCallback((ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    track('login_show_more', { category: 'auth', action: 'login' });
    setShowMore(true);
  }, [ track ]);


  return (
    <>
      <GoogleButton>{t('sign_in_with_google')}</GoogleButton>

      {(isMacLike || Capacitor.getPlatform() === 'ios') && (
        <AppleButton>{t('sign_in_with_apple')}</AppleButton>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={ () => router('magic') }
        fullWidth
        startIcon={<Email style={{ fontSize: '1.0rem'}} />}
        sx={{ mb: 1 }}
      >
        {t('sign_in_with_email')}
      </Button>

      {!showMore &&
        <Typography variant="body2" component="div" sx={{ textAlign: 'center', mt: 1 }}>
          <Link underline="none" sx={{ display: 'inline-flex', alignItems: 'flex-end', color: 'text.secondary' }} onClick={handleClickShowMore}>
            {t('sign_in_with')}&nbsp;
            {/* Exclude Apple from Android */ Capacitor.getPlatform() === 'web' && !isMacLike && (
              <Apple fontSize='medium' />
            )}
            <Twitter fontSize='medium' />
            <Facebook fontSize='medium' />
          </Link>
        </Typography>
      }

      {showMore &&
        <>
          {/* Exclude Apple from Android */ Capacitor.getPlatform() === 'web' && !isMacLike && (
            <AppleButton>{t('sign_in_with_apple')}</AppleButton>
          )}
          <TwitterButton>{t('sign_in_with_twitter')}</TwitterButton>
          <FacebookButton>{t('sign_in_with_facebook')}</FacebookButton>
        </>
      }

      <Divider sx={{ mt: 2, mb: 3 }}>{t('or')}</Divider>

      <form onSubmit={login}>

        <EmailInput email={email} setEmail={setEmail} />

        <PasswordInput password={password} setPassword={setPassword} />

        <Typography variant="body2" component="div" sx={{ textAlign: 'right', pb: 3 }}>
          <Link onClick={() => router('forgot')}>{t('forgot_password?')}</Link>
        </Typography>

        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          loading={loading}
          disabled={ !password || !EmailValidator.validate(email)}
          data-cy='login-submit'
        >
          {t('log_in')}
        </LoadingButton>

      </form>

      <Typography variant="body1" sx={{ mt: 4, textAlign: 'center' }}>
        {t('need_an_account')} <Link onClick={() => router('register')} data-cy='auth-register'>{t('register')}</Link>
      </Typography>

      <Typography variant='body2' sx={{
        mt: 5,
        display: 'flex',
        justifyContent: 'center',
        gap: 1,
        color: 'text.secondary',
      }}>
        <a href={process.env.REACT_APP_CURRENT_TERMS} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
          {t('terms_of_service')}
        </a>
        |
        <a href='https://icecream.club/legal/privacy' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
          {t('privacy_policy')}
        </a>
      </Typography>
    </>
  );
}
