import { FC, SyntheticEvent, useEffect } from 'react';
import { Box, Divider, List, ListItem } from '@mui/material';

import { CanonicalContent, Contact, Share, useCreateInvitationMutation } from '../../generated/graphql';
import { useContact } from '../../hooks/use-contact';
import { useSession } from '../../hooks/auth';
import { canonicalLink } from '../../utils/routes';
import { BottomSheet } from '../BottomSheet';
import { FlagButton } from '../FlagButton';
import { BlockContactButton } from '../BlockContactButton';
import { ExternalShareButton, ExternalShareButtonProps } from '../ExternalShareButton';
import { ReshareButton } from '../ReshareButton';
import { DeleteButton } from './DeleteButton';
import { CopyInviteLinkButton } from '../CopyInviteLinkButton';


export type MoreSheetProps = {
  share?: Pick<Share, '_id'> & {
    sender?: Pick<Contact, '_id'> | null,
  } & ExternalShareButtonProps['share'] | null,
  canonical?: Pick<CanonicalContent, '_id'> & ExternalShareButtonProps['share'] | null,
  variant?: 'all' | 'share',
  onClose: (ev?: SyntheticEvent) => void,
}


export const MoreSheet: FC<MoreSheetProps> = function MoreSheet({ share, canonical, variant='all', onClose }) {
  const { user } = useSession();

  const { data: contactData } = useContact(share?.sender?._id);
  const sender = contactData?.contact;

  const [createInvitation, { data, loading, error }] = useCreateInvitationMutation();
  if(error) {
    console.error(error);
  }
  useEffect(() => {
    if(user) {
      createInvitation({ variables: { next: canonicalLink(canonical?._id, share?._id), from: 'post' } });
    }
  }, [createInvitation, share, canonical, user]);

  const link = data?.createInvitation?.invitationLink;

  return (
    <BottomSheet top={variant === 'share' ? '75%' : '60%'} onClose={onClose} onOpen={()=>{}}>
      <Box sx={{ display: 'flex', flexFlow: 'row wrap', pt: 1 }}>
        <List disablePadding sx={{ width: '100%' }}>

          <ListItem sx={{ px: 0, display: 'grid', width: '100%', gridGap: 16, gridTemplateColumns: '1fr 1fr' }}>
            <ExternalShareButton link={link} share={share} canonical={canonical} loading={loading} />

            <CopyInviteLinkButton link={link || ''} origin='post' loading={loading} />
          </ListItem>

          <ListItem sx={{ px: 0 }}>
            <ReshareButton share={share} canonical={canonical} onClick={onClose} />
          </ListItem>


          {sender?._id !== user?._id && variant === 'all' && share &&
            <>
              <Divider component='li' sx={{ my: 1 }} />

              <FlagButton share={share} />

              {sender &&
                <BlockContactButton contact={sender} />
              }
            </>
          }

          {user && sender?._id === user._id && variant === 'all' && share &&
            <>
              <Divider component='li' sx={{ my: 1 }} />

              <DeleteButton shareId={share._id} onDelete={onClose} />
            </>
          }
        </List>

      </Box>
    </BottomSheet>
  );
}
