import { useCallback, useEffect, useState, useMemo, FC } from 'react';
import { Redirect, useParams, useHistory, useLocation } from 'react-router-dom';
import { Container as MuiContainer, Typography, Box } from '@mui/material';

import { useSession } from '../../hooks/auth';
import { loadLanguageBundle, useTranslation } from '../../utils/i18n';
import { relativize } from '../../utils/url';
import { isEmpty } from '../../utils/object';
import { Login, Register, Logout, Welcome, Forgot, Magic, Reset } from '.';
import { Test } from './Test';

import language from '../../components/auth/language.json';
loadLanguageBundle('auth', language)


export type Router = (action: 'login' | 'register' | 'test' | 'magic' | 'forgot', params?: { next?: string, oobCode?: string }) => void;


export const Container: FC = function Container() {

  const { action } = useParams<{ action: string }>();
  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation('auth');

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const nextSearchParam = searchParams.get('next');
  const continueUrlSearchParam = searchParams.get('continueUrl');
  let next = '';
  if(nextSearchParam) {
    next = relativize(nextSearchParam);
  } else if(continueUrlSearchParam) {
    next = relativize(continueUrlSearchParam);
  }
  const oobCode = searchParams.get('oobCode');
  const defaultAction = searchParams.get('mode') ===  'resetPassword' ? 'reset' : 'welcome';

  const { auth } = useSession();
  const [ password, setPassword ] = useState('');
  const [ email, setEmail ] = useState('');

  useEffect(() => {
    document.body.classList.add('auth-open');
    return () => document.body.classList.remove('auth-open');
  }, []);

  const router: Router = useCallback((action, params) => {
    const paramsObj = { ...params };
    if (next) paramsObj.next = next;
    if (oobCode) paramsObj.oobCode = oobCode;
    let search = '';
    if (!isEmpty(paramsObj)) {
      search = `?${(new URLSearchParams(paramsObj)).toString()}`;
    }
    history.push(`/auth/${action}${search}`);
  }, [ history, next, oobCode ]);

  const { title, component, backgroundColor, cardColor, textColor } = useMemo(() => {

    let title,
      component,
      backgroundColor = '#fff',
      cardColor = 'primary.background',
      textColor = 'text.primary';

    if(auth && action !== 'logout') {
      component = <Redirect to={next || '/feed'} />;

    } else if(action === 'register') {
      title = t('register');
      component = <Register router={router} email={email} setEmail={setEmail} password={password} setPassword={setPassword} />;

    } else if(action === 'logout') {
      component = <Logout next={next || '/auth/welcome'} />;

    } else if(action === 'login') {
      title = t('login');
      component = <Login router={router} email={email} setEmail={setEmail} password={password} setPassword={setPassword} />;

    } else if (action === 'welcome') {
      backgroundColor = 'primary.background';
      cardColor = 'primary.background';
      textColor = 'text.primary';
      component = <Welcome router={router} />;

    } else if (action === 'forgot') {
      title = <>{t('forgot_password')}<br />🗝</>;
      component = <Forgot email={email} setEmail={setEmail} />;

    } else if (action === 'reset') {
      title = <>Reset password <br />🗝</>;
      component = <Reset oobCode={oobCode || ''} router={router} email={email} setEmail={setEmail} />;

    } else if (action === 'magic') {
      title = <>{t('magic')} 🪄</>;
      component = <Magic email={email} setEmail={setEmail} />;

    } else if (action === 'test') {
      title = <>Test</>;
      component = <Test router={router} />;

    } else {
      const paramsObj: { next?: string, oobCode?: string } = {};
      if (next) paramsObj.next = next;
      if (oobCode) paramsObj.oobCode = oobCode;
      const paramsStr = (new URLSearchParams(paramsObj)).toString();
      component = <Redirect to={`/auth/${defaultAction}${next || oobCode  ? `?${paramsStr}` : ''}`} />;
    }

    return { title, component, backgroundColor, cardColor, textColor };

  }, [ action, auth, email, next, oobCode, password, defaultAction, router, t ]);

  return (
    <MuiContainer
      maxWidth="sm"
      disableGutters={true}
      sx={{
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor
      }}
    >

      <Box
        sx={{
          display: 'flex',
          minHeight: 0,
          flexDirection: 'column',
        }}
      >

        <Box
          sx={{
            flex: '1 1 auto',
            p: 3,
            m: 2,
            my: 6,
            backgroundColor: cardColor,
            color: textColor,
            borderRadius: 1,
          }}
        >

          {title && (
            <Typography variant="h1" sx={{ mb: 3, textAlign: 'center' }}>
              {title}
            </Typography>
          )}

          {component}

        </Box>

      </Box>
    </MuiContainer>
  );
}
