import { FC, SyntheticEvent } from "react";
import { IconButton, SxProps } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { CanonicalContent, Share } from "@/generated/graphql";
import { useAnalyticsQueued } from "@/hooks/delicious-analytics";
import { useIsInWatchlist, useToggleInWatchlist } from "@/hooks/watch-state";
import { AddCircle, AddCircleFilled } from "../icons";


export type WatchlistButtonProps = {
  share?: Pick<Share, '_id'> | null,
  canonicalContent?: Pick<CanonicalContent, '_id'> | null
  sx?: SxProps,
}


export const WatchlistButton: FC<WatchlistButtonProps> = function WatchlistButton({ share, canonicalContent, sx={} }) {

  const theme = useTheme();
  const { track } = useAnalyticsQueued();

  const isInWatchlist = useIsInWatchlist(share?._id, canonicalContent?._id);
  const toggleInWatchlist = useToggleInWatchlist();
  const setIsInWatchlist = (v: boolean) => toggleInWatchlist(canonicalContent?._id, share?._id, v);

  const handleClickWatchlist = (ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();

    track('toggle_watchlist_button', { category: 'feed' });
    setIsInWatchlist(!isInWatchlist);
  };

  if(!share?._id && !canonicalContent?._id) {
    console.error('WatchlistButton: share or canonicalContent must be defined');
    return null;
  }

  return (
    <IconButton
      onClick={handleClickWatchlist}
      size="small"
      sx={{ height: '24px', display: 'flex', flexDirection: 'row', p: 0, ...sx }}
    >
      {isInWatchlist ?
        (<AddCircleFilled sx={{ width: 14, height: 14, color: 'primary.main', mb: '1px' }} />) :
        (<AddCircle sx={{ width: 14, height: 14, color: theme.palette.grey[500], strokeWidth: 2, stroke: theme.palette.grey[500], mb: '1px' }} />)
      }
    </IconButton>
  );
}
