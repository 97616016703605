import { Analytics } from 'analytics';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { Capacitor } from "@capacitor/core";
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

import { filter, removeEmpty } from './utils/object';
import { isServer, localStorageAvailable } from './utils/detect';


const enableAnalytics = process.env.NODE_ENV === 'production' && !isServer;

const IDENTIFY_DEBOUNCE = 1000;
const TRACK_EVENT_LIMT = 1000;

let loaded = false;
let loggedCampaignParams = null;

if (enableAnalytics) {
  loaded = true;
} else if(!isServer) {
  // in native platforms Firebase is initialized by the native SDK automatically, so we need to disable
  FirebaseAnalytics.setEnabled({ enabled: false }).catch(err => console.error('FirebaseAnalytics.disable', err));
}


// wraps firebase plugin for use with Analytics
function firebaseAnalyticsPlugin() {
  return {
    name: 'firebase-analytics',
    initialize: () => {},
    page: ({ payload }) => {
      if (payload.properties.campaignParams) {
        const campaignParams = removeEmpty({
          firstPath: payload.properties.campaignParams.path,
          source: payload.properties.campaignParams.source,
          medium: payload.properties.campaignParams.medium,
          campaign: payload.properties.campaignParams.campaign,
          content: payload.properties.campaignParams.content,
          link_by: payload.properties.campaignParams.link_by,
          invite_to: payload.properties.campaignParams.invite_to,
          referrer: payload.properties.campaignParams.referrer,
          from: payload.properties.campaignParams.from,
          probable_in_app_browser: payload.properties.campaignParams.probableInAppBrowser,
          in_app_token: payload.properties.campaignParams.inAppToken,
          device_id: payload.properties.device_id,
          category: 'pageview',
        });
        // only log if campaign params have changed
        if(JSON.stringify(loggedCampaignParams) !== JSON.stringify(campaignParams)) {
          FirebaseAnalytics.logEvent({ name: 'campaign_details', params: campaignParams }).then(() => loggedCampaignParams = campaignParams).catch(err => console.error('FirebaseAnalytics campaign_details', err));
        }
      }

      const screenName = payload.properties.path.replace('//', '/');

      // custom event name as some events seem to be dropped
      FirebaseAnalytics.logEvent({
        name: 'page_location',
        params: removeEmpty({ page_path: screenName, page_location: payload.properties.url, category: 'pageview', device_id: payload.properties.device_id })
      }).catch(err => console.error('FirebaseAnalytics screen_view', err));

      if(Capacitor.isNativePlatform()) {
        // screen_view is for GA4 apps
        FirebaseAnalytics.logEvent({
          name: 'screen_view',
          params: removeEmpty({ screen_name: screenName, screen_class: 'UIViewController', category: 'pageview', device_id: payload.properties.device_id })
        }).catch(err => console.error('FirebaseAnalytics screen_view', err));
      } else {
        // page_view is for GA4 web
        FirebaseAnalytics.logEvent({
          name: 'page_view',
          params: removeEmpty({ page_path: screenName, page_title: screenName, page_location: payload.properties.url, category: 'pageview', send_page_view: true, device_id: payload.properties.device_id })
        }).catch(err => console.error('FirebaseAnalytics page_view', err));
      }
    },
    track: ({ payload }) => {
      FirebaseAnalytics.logEvent({
        name: payload.event,
        params: payload.properties
      }).catch(err => console.error(`FirebaseAnalytics ${payload.event}`, err));
    },
    identify: debounce(({ payload }) => {
      FirebaseAnalytics.setUserId({
        userId: payload.userId
      }).catch(err => console.error('FirebaseAnalytics identify', err));
      for (const [key, value] of Object.entries(payload.traits)) {
        if (key && value) {
          FirebaseAnalytics.setUserProperty({ key, value }).catch(err => console.error('FirebaseAnalytics identify setUserProperty', err, key, value));
        }
      }
    }, IDENTIFY_DEBOUNCE),
    loaded: () => {
      return loaded;
    },
  }
}


function sstGtagPlugin() {
  return {
    name: 'sst-gtag',
    initialize: () => {
      var script = document.createElement('script');
      script.src = 'https://sst.icecream.club/gtag/js?id=G-LFKPZHE724&l=sstGtagDataLayer';
      script.async = true;
      document.head.appendChild(script);
      window.sstGtagDataLayer = window.sstGtagDataLayer || [];
      function sstGtag(){window.sstGtagDataLayer.push(arguments);}
      sstGtag('js', new Date());

      sstGtag('config', 'G-LFKPZHE724', {
        transport_type: 'beacon',
        transport_url: 'https://sst.icecream.club',
        first_party_collection: true,
        send_page_view: false,
      });
      window.sstGtag = sstGtag;
    },
    page: ({ payload }) => {
      if (payload.properties.campaignParams) {
        const campaignParams = removeEmpty({
          firstPath: payload.properties.campaignParams.path,
          source: payload.properties.campaignParams.source,
          medium: payload.properties.campaignParams.medium,
          campaign: payload.properties.campaignParams.campaign,
          content: payload.properties.campaignParams.content,
          link_by: payload.properties.campaignParams.link_by,
          invite_to: payload.properties.campaignParams.invite_to,
          referrer: payload.properties.campaignParams.referrer,
          from: payload.properties.campaignParams.from,
          probable_in_app_browser: payload.properties.campaignParams.probableInAppBrowser,
          in_app_token: payload.properties.campaignParams.inAppToken,
          device_id: payload.properties.device_id,
          category: 'pageview',
        });
        // only log if campaign params have changed
        if(JSON.stringify(loggedCampaignParams) !== JSON.stringify(campaignParams)) {
          window.sstGtag({ name: 'campaign_details', params: campaignParams });
        }
      }

      const screenName = payload.properties.path.replace('//', '/');

      if(window.sstGtag) {
        window.sstGtag('event', 'page_view', {
          page_title: payload.properties.title,
          page_location: payload.properties.url,
          page_path: screenName,
          device_id: payload.properties.device_id,
        });
      }
    },
    track: ({ payload }) => {
      if(window.sstGtag) {
        window.sstGtag('event', payload.event, payload.properties);
      }
    },
    identify: ({ payload }) => {
      if(window.sstGtag) {
        window.sstGtag('set', 'user_id', payload.userId);
        for (const [key, value] of Object.entries(payload.traits)) {
          if (key && value) {
            window.sstGtag('set', key, value);
          }
        }
      }
    },
    loaded: () => {
      return !!window.sstGtag;
    },
  };
}

const location = {};
const events = {};
const identifyTraits = {};

// validate incoming events
const validationPlugin = {
  name: 'delicious-validation',
  pageStart: ({ payload, abort }) => {
    if (location?.path === payload.properties.path) {
      return abort('Same path as previous');
    }
  },
  trackStart: ({ payload, abort }) => {
    if (payload.event.match(/-/g) !== null) {
      console.error(`analytics.js: Event name must use snake_case: ${payload.event}`);
      return abort('Event name must use snake_case');
    }
    if (!payload.properties?.category) {
      console.error(`analytics.js: Event missing category for event:${payload.event}`);
      return abort('Event missing category');
    }
    if (payload.event.length > 40) {
      console.error(`analytics.js: Event name exceeded 40 chars: ${payload.event}`);
      return abort(`Event name exceeded 40 chars: ${payload.event}`);
    }
    for(const [ key, value ] of Object.entries(payload.properties)) {
        if (!Object.prototype.hasOwnProperty.call(payload.properties, key)) continue;
        if(typeof value == 'object') {
          console.error(`analytics.js: Event payload exceeded maximum depth for event:${payload.event} key:${key}`, payload.event, key, value);
          return abort('Event payload exceeded maximum depth');
        }
    }
    if (isEqual(events[payload.event], payload.properties)) {
      return abort(`Event ${payload.event} already tracked within ${TRACK_EVENT_LIMT}ms`);
    }
    events[payload.event] = payload.properties;
    setTimeout(() => {
      delete events[payload.event];
    }, TRACK_EVENT_LIMT);
  },
  identifyStart: ({ payload, abort }) => {
    const prev = identifyTraits[payload.userId];
    const traits = { ...identifyTraits[payload.userId], ...payload.traits };
    if (isEqual(prev, traits)) {
      return abort('Identify traits is the same as previous identify');
    }
    identifyTraits[payload.userId] = traits;
    return { ...payload, traits };
  }
}


const transformToStrings = (obj) => {
  let transformed = {};
  for (const [ key, value ] of Object.entries(obj)) {
    if (typeof value == "boolean") {
      transformed[key] = value === true ? '1' : '0';
    } else if (typeof value == "number") {
      transformed[key] = `${value}`;
    }
  }
  return { ...obj, ...transformed };
};

let appInfo = {};
let deviceId = null;
let deviceGetInfoReady = false, deviceGetBatteryInfoReady = false, appGetInfoReady = false, deviceIdReady = false;

if(isServer) {
  deviceGetInfoReady = true;
  deviceIdReady = true;
} else {
  // gather device info
  Device.getInfo().then(info => {
    appInfo = {...appInfo, ...info};
  }).catch(err => {
    console.error('Device.getInfo', err);
  }).finally(() => {
    deviceGetInfoReady = true;
  });

  Device.getId().then(({ identifier }) => {
    deviceId = identifier;
  }).catch(err => {
    if(localStorageAvailable) {
      console.error('Device.getId error', err);
    }
  }).finally(() => {
    deviceIdReady = true;
  });
}

if(Capacitor.isNativePlatform()) {
  // gather app info
  Device.getBatteryInfo().then(batteryInfo => {
    appInfo = {...appInfo, ...batteryInfo};
  }).catch(err => {
    console.error('Device.getBatteryInfo', err);
  }).finally(() => {
    deviceGetBatteryInfoReady = true;
  });

  App.getInfo().then(info => {
    appInfo = {...appInfo, ...info };
  }).catch(err => {
    console.error('App.getInfo', err);
  }).finally(() => {
    appGetInfoReady = true;
  });
} else {
  deviceGetBatteryInfoReady = true;
  appGetInfoReady = true;
}

// add meta data to events, pages and identify
const enrichPlugin = {
  name: 'delicious-enrich',
  config: {
    sessionTraits: ['platform', 'version', 'build', 'push_enabled', 'push_reason', 'diskTotal', 'isVirtual', 'isCharging', 'batteryLevel', 'diskFree', 'memUsed', 'device_id'],
    userTraits: ['last_seen_cap_platform', 'last_seen_ios_app', 'push_enabled_ios', 'push_reason_ios', 'last_seen_android_app', 'push_enabled_android', 'push_reason_android', 'completeness', 'is_testflight']
  },
  trackStart: ({ payload }) => {
    const extend = {};
    if(deviceId) {
      extend.device_id = deviceId;
    }
    // some platforms only supports strings
    const properties = transformToStrings({ ...payload.properties, ...extend });
    return { ...payload, properties };
  },
  pageStart: ({ payload }) => {
    const extend = {};
    if(deviceId) {
      extend.device_id = deviceId;
    }
    // some platforms only supports strings
    const properties = transformToStrings({ ...payload.properties, ...extend });
    return { ...payload, properties };
  },
  identifyStart: ({ payload }) => {
    let extend = {};
    if (Capacitor.isNativePlatform()) {
      extend.is_native_platform = 1;

      if (Capacitor.getPlatform() === 'ios') {
        extend.last_seen_ios_app = `${appInfo.version} (${appInfo.build})`;
      } else if (Capacitor.getPlatform() === 'android') {
        extend.last_seen_android_app = `${appInfo.version} (${appInfo.build})`;
      }
    }

    if(typeof payload.traits?.pushEnabled === 'boolean' || payload.traits?.pushReason) {
      if (Capacitor.getPlatform() === 'ios') {
        extend.push_enabled_ios = payload.traits.pushEnabled;
        extend.push_reason_ios = payload.traits.pushReason;
      } else if (Capacitor.getPlatform() === 'android') {
        extend.push_enabled_android = payload.traits.pushEnabled;
        extend.push_reason_android = payload.traits.pushReason;
      }

      extend.push_enabled = payload.traits.pushEnabled;
      extend.push_reason = payload.traits.pushReason;
      delete payload.traits.pushEnabled;
      delete payload.traits.pushReason;
    }

    extend.last_seen_cap_platform = appInfo.platform;

    if(deviceId) {
      extend.device_id = deviceId;
    }

    // some platforms only supports strings
    const traits = transformToStrings({ ...payload.traits, ...extend });
    return { ...payload, traits };
  },
  'identify:firebase-analytics': ({ payload, config }) => {
    const userTraits = filter(payload.traits, config.userTraits);
    const sessionTraits = filter(payload.traits, config.sessionTraits);
    return { ...payload, traits: { ...userTraits, ...sessionTraits } };
  },
  loaded: () => {
    return deviceGetInfoReady && deviceGetBatteryInfoReady && appGetInfoReady && deviceIdReady;
  }
};

// dev only plugins
const devPlugin = {
  name: 'delicious-logger',
  params: ({ payload }) => {
    console.info('analytics | params', payload);
  },
  campaign: ({ payload }) => {
    console.info('analytics | campaign', payload);
  },
  page: ({ payload }) => {
    console.info('analytics | page | call', payload.properties.path, payload);
  },
  track: ({ payload }) => {
    console.info('analytics | track | call', payload);
  },
  identify: debounce(({ payload }) => {
    console.info('analytics | identify | call', payload);
  }, IDENTIFY_DEBOUNCE),
  ready: ({ payload }) => {
    console.info('analytics | ready', payload);
  },
};

// register plugins to Analytics
const plugins = enableAnalytics ? [
  enrichPlugin,
  validationPlugin,
  firebaseAnalyticsPlugin(),
  sstGtagPlugin(),
] : [
  enrichPlugin,
  validationPlugin,
  devPlugin
];


const analytics = Analytics({
  app: 'icecream.club',
  plugins,
});

export default analytics;

