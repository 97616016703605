
import { useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { useCurrentUserActionsQuery } from '@/generated/graphql';


export function useCompleteness() {

  const { data, loading, error } = useCurrentUserActionsQuery();

  const [ hidden, setHidden ] = useLocalStorage<boolean>('completeness-hidden', false);

  const retval = useMemo(() => {
    const {
      follows,
      invites,
      privateLists,
      publicLists,
      // followedLists,
      publicShares,
      groupShares,
      // listShares,
      listItems,
      avatar,
      createdGroups,
      // pushEnabled,
    } = data?.currentUser?.actions || {};

    const actions = {
      follows: { value: follows || 0, goal: 5 },
      invites: { value: invites || 0, goal: 1 },
      collections: { value: (privateLists && publicLists) ? privateLists + publicLists : 0, goal: 2 },
      // followedLists,
      shares: { value: (publicShares && groupShares) ? publicShares + groupShares : 0, goal: 4 },
      // listShares,
      listItems: { value: listItems || 0, goal: 4 },
      avatar: { value: avatar ? 1 : 0, goal: 1 },
      createdGroups: { value: createdGroups || 0, goal: 1 },
      // pushEnabled,
    };

    const total = Object.values(actions).reduce((acc, item) => acc + item.goal, 0);
    const progress = Object.values(actions).reduce((acc, item) => acc + Math.min(item.value, item.goal), 0);
    const progressPct = Math.floor(100 * progress / total);

    return {
      data: {
        actions,
        total,
        progress,
        progressPct
      },
      loading,
      error,
      hidden,
      setHidden,
    };
  }, [ data, loading, error, hidden, setHidden ]);

  return retval;
}
